import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { GP, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser, mapFieldsOptionsToLabel, truncateMessage } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';
import { AspectRatioWrapper, H3, H6, NamedLink, ResponsiveImage } from '..';
import { IoStar } from "react-icons/io5";

import css from './ListingDoctorCard.module.css';
import { IoIosStarHalf } from 'react-icons/io';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
    if (price && price.currency === currency) {
        const formattedPrice = formatMoney(intl, price);
        return { formattedPrice, priceTitle: formattedPrice };
    } else if (price) {
        return {
            formattedPrice: intl.formatMessage(
                { id: 'ListingCard.unsupportedPrice' },
                { currency: price.currency }
            ),
            priceTitle: intl.formatMessage(
                { id: 'ListingCard.unsupportedPriceTitle' },
                { currency: price.currency }
            ),
        };
    }
    return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
    const { price, publicData, config, intl } = props;
    const { listingType } = publicData || {};
    const validListingTypes = config.listing.listingTypes;
    const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
    const showPrice = displayPrice(foundListingTypeConfig);
    if (!showPrice && price) {
        return null;
    }

    const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
    const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
    return (
        <div className={css.price}>
            <div className={css.priceValue} title={priceTitle}>
                {formattedPrice}
            </div>
            {isBookable ? (
                <div className={css.perUnit}>
                    <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
                </div>
            ) : null}
        </div>
    );
};

export const ListingDoctorCardComponent = props => {
    const config = useConfiguration();
    const {
        className,
        rootClassName,
        intl,
        listing,
        renderSizes,
        setActiveListing,
        showAuthorInfo,
        listingFields
    } = props;

    const specialityEnumOptions = listingFields?.find(lc => lc.key === 'speciality')?.enumOptions;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureListing(listing);
    const id = currentListing.id.uuid;
    const { title = '', price, publicData } = currentListing.attributes;
    const { education, category, work_experience, profileImage, rating = 0, reviews = 0, speciality } = publicData || {};



    const avgRating = rating;
    const totalRatings = reviews;
    const starArray = [1, 2, 3, 4, 5];
    // Rating End
    const slug = createSlug(title);
    // const author = ensureUser(listing.author);
    const authorName = title;
    //   const firstImage =
    //     currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
    // const firstImage = (author && author?.id && author?.profileImage);

    const {
        aspectWidth = 1,
        aspectHeight = 1,
        variantPrefix = 'listing-card',
    } = config.layout.listingImage;
    // const variants = firstImage
    //     ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    //     : [];

    const setActivePropsMaybe = setActiveListing
        ? {
            onMouseEnter: () => setActiveListing(currentListing.id),
            onMouseLeave: () => setActiveListing(null),
        }
        : null;

    const showStarsByRatings = (ratingPeruser, totalRatings, starArray) => {
        const filledStars = Math.floor(ratingPeruser);
        const hasHalfStar = ratingPeruser % 1 !== 0;

        return (
            <div className={css.ratingContent}>
                {starArray.map(star => {
                    return star === (filledStars + 1) && hasHalfStar
                        ? <IoIosStarHalf className={css.starIconGold
                        } key={star} />
                        : <IoStar
                            className={
                                star <= filledStars
                                    ? css.starIconGold
                                    : css.starIconGrey
                            }
                            key={star}
                        />

                })}
                <H6 className={css.ratingText}>{ratingPeruser.toFixed(1)} {`(${totalRatings} Review${totalRatings !== 1 ? 's' : ''})`}</H6>
            </div>
        );
    };

    const author = ensureUser(listing.author);
    const firstImage = (author && author?.id && author?.profileImage);
    const variants = firstImage
        ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
        : [];

    const doctorCardExperience = intl.formatMessage({ id: 'DoctorCard.experience' });
    const doctorCardFee = intl.formatMessage({ id: 'DoctorCard.fee' });

    const educationMaybe = !!education ? (
        <div className={css.country}>{truncateMessage(education, 6)}</div>
    ) : null;
   
    const specialitiesMaybe = !!speciality ? (
        <div className={css.specialityContainer}>
            {speciality}
        </div>
    ) : null;
    const categoryMaybe = !!category && category === GP ? (
        <div className={css.category}>{category}</div>
    ) : null;
    const experienceMaybe = !!work_experience ? (
        <>
            <p className={css.label}>{doctorCardExperience}</p>
            <p className={css.dataInfo}>{truncateMessage(work_experience, 5)}</p>
        </>
    ) : null;
    const feeMaybe = !!price ? (
        <>
            <p className={css.label}>{doctorCardFee}</p>
            <div className={css.dataInfo}><PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
            </div>
        </>
    ) : null;

    return (
        <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
            <AspectRatioWrapper
                className={css.aspectRatioWrapper}
                width={aspectWidth}
                height={aspectHeight}
                {...setActivePropsMaybe}
            >
                <LazyImage
                    rootClassName={css.rootForImage}
                    alt={title}
                    image={firstImage}
                    variants={variants}
                    sizes={renderSizes}
                />
            </AspectRatioWrapper>
            <div className={css.info}>
                <div className={css.mainInfo}>
                    <div className={css.Cardheader}>
                        <div className={css.headerLeft}>
                            {showAuthorInfo ? (
                                <div className={css.authorInfo}>
                                    <FormattedMessage id="ListingCard.author" values={{ authorName: truncateMessage(authorName, 3) }} />
                                </div>
                            ) : null}
                            {educationMaybe}
                        </div>
                        {showStarsByRatings(avgRating, totalRatings, starArray)}
                    </div>
                    {categoryMaybe}
                    {specialitiesMaybe}
                    {experienceMaybe}
                    {feeMaybe}
                </div>
            </div>
        </NamedLink>
    );
};

ListingDoctorCardComponent.defaultProps = {
    className: null,
    rootClassName: null,
    renderSizes: null,
    setActiveListing: null,
    showAuthorInfo: true,
};

ListingDoctorCardComponent.propTypes = {
    className: string,
    rootClassName: string,
    intl: intlShape.isRequired,
    listing: propTypes.listing.isRequired,
    showAuthorInfo: bool,
    // Responsive image sizes hint
    renderSizes: string,
    setActiveListing: func,
};

export default injectIntl(ListingDoctorCardComponent);
