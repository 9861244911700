import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser, truncateMessage } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';
import { AspectRatioWrapper, Button, H3, H4, H5, H6, NamedLink, ResponsiveImage } from '..';
import { IoStar } from "react-icons/io5";

import css from './ListingJobCard.module.css';
import moment from 'moment';
import appSettings from '../../config/settings';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
    if (price && price.currency === currency) {
        const formattedPrice = formatMoney(intl, price);
        return { formattedPrice, priceTitle: formattedPrice };
    } else if (price) {
        return {
            formattedPrice: intl.formatMessage(
                { id: 'ListingCard.unsupportedPrice' },
                { currency: price.currency }
            ),
            priceTitle: intl.formatMessage(
                { id: 'ListingCard.unsupportedPriceTitle' },
                { currency: price.currency }
            ),
        };
    }
    return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
    const { price, publicData, config, intl, feeNumber } = props;
    const { listingType } = publicData || {};
    const validListingTypes = config.listing.listingTypes;
    const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
    const showPrice = displayPrice(foundListingTypeConfig);
    if (!showPrice && price) {
        return null;
    }

    const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
    const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
    return (
        <div className={css.price}>
            <div className={classNames(css.priceValue, feeNumber && css.feePriceValue)} title={priceTitle}>
                {formattedPrice}
            </div>
            {isBookable ? (
                <div className={classNames(css.perUnit, feeNumber && css.feePerUnit)}>
                    <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
                </div>
            ) : null}
        </div>
    );
};

export const ListingJobCardComponent = props => {
    const config = useConfiguration();
    const currencyConfig = appSettings.getCurrencyFormatting(config.currency);
    const {
        className,
        rootClassName,
        intl,
        listing,
        renderSizes,
        setActiveListing,
        showAuthorInfo,
    } = props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureListing(listing);
    const id = currentListing.id.uuid;
    const { title = '', description, publicData, createdAt } = currentListing.attributes;
    const { work_experience, category, location, postDates, session, startTime, endTime, prices, timezone } = publicData || {};
    const { endDate: isoEnd, startDate: isoStart } = postDates || {};
    const startDate = isoStart && timezone ? moment(isoStart).tz(timezone).format("DD MMM YYYY") : null;
    const endDate = isoEnd && timezone ? moment(isoEnd).tz(timezone).format("DD MMM YYYY") : null;
    const { min, max } = prices || {};
    const maxPrice = !!max && max.amount ? max.amount / 100 : null;
    const minPrice = !!min && min.amount ? min.amount / 100 : null;

    const { address = '' } = location || {};
    const addressArr = address.split(",");
    const countryOfOrigin = addressArr[addressArr.length - 1];
    const locationWOcountry = addressArr.splice(0, addressArr.length - 1).join(',');
    const slug = createSlug(title);
    const author = ensureUser(listing.author);
    const authorName = author.attributes.profile.displayName;
    const postedAt = createdAt ? moment(createdAt).fromNow() : null;

    const firstImage = (author && author?.id && author?.profileImage);

    const {
        aspectWidth = 1,
        aspectHeight = 1,
        variantPrefix = 'listing-card',
    } = config.layout.listingImage;
    const variants = firstImage
        ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
        : [];

    const setActivePropsMaybe = setActiveListing
        ? {
            onMouseEnter: () => setActiveListing(currentListing.id),
            onMouseLeave: () => setActiveListing(null),
        }
        : null;

    const locationTitle = intl.formatMessage({ id: "JobCard.locationTitle" });
    const feeTitle = intl.formatMessage({ id: "JobCard.feeTitle" });
    const availabilityTitle = intl.formatMessage({ id: "JobCard.availabilityTitle" });
    const experienceTitle = intl.formatMessage({ id: "JobCard.experienceTitle" });
    const availability = intl.formatMessage({ id: "JobCard.availability" }, { startDate, endDate });
    const timingTitle = intl.formatMessage({ id: "JobCard.timingTitle" });
    const timing = intl.formatMessage({ id: "JobCard.timing" }, { startTime, endTime });
    const jobPostedAt = intl.formatMessage({ id: "JobCard.jobPosted" }, { postedAt });
    const categoryTitle = intl.formatMessage({ id: "JobCard.categoryTitle" });
    const proposalBtnText = intl.formatMessage({ id: "JobCard.proposalBtnText" });
    const saveBtnText = intl.formatMessage({ id: "JobCard.saveBtnText" });
    

    const locationMaybe = !!locationWOcountry ? (
        <>
            <H6 className={css.label}>{locationTitle}</H6>
            <H5 className={css.infoData}>{truncateMessage(locationWOcountry,5)}</H5>
        </>
    ) : null;

    const pricingMaybe = minPrice && maxPrice ? (
        <div>
            <H6 className={css.label}>{feeTitle}</H6>
            <div className={css.infoData}>
                <span>{`${intl.formatNumber(minPrice, currencyConfig)} - ${intl.formatNumber(maxPrice, currencyConfig)}`}</span>
            </div>
        </div>
    ) : null;

    const experienceMaybe = !!work_experience ? (
        < >
            <p className={css.label}>{experienceTitle}</p>
            <p className={css.infoData}>{truncateMessage(work_experience, 2)}</p>
        </>
    ) : null;

    const categoryMaybe = !!category ? (
        <>
            <p className={css.label}>{categoryTitle}</p>
            <H5 className={css.infoData}>{category}</H5>
        </>
    ) : null;

    const availabilityMaybe = !!startDate && !!endDate ? (
        <>
            <H6 className={css.label}>{availabilityTitle}</H6>
            <H5 className={css.infoDataNoMargin}>{availability}</H5>
            {startTime && endTime ? <div><H6 className={css.label}>{timingTitle}</H6><H5 className={css.infoData}>{timing}</H5></div> : null}
        </>
    ) : null;

    return (
        <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
            <div className={css.mainInfoWrapper}>
                <div className={css.cardHeader}>
                    <div className={css.profileInfo}>
                        <AspectRatioWrapper
                            className={css.aspectRatioWrapper}
                            width={aspectWidth}
                            height={aspectHeight}
                            {...setActivePropsMaybe}
                        >
                            <LazyImage
                                rootClassName={css.rootForImage}
                                alt={title}
                                image={firstImage}
                                variants={variants}
                                sizes={renderSizes}
                            />
                        </AspectRatioWrapper>
                        <div>
                            {showAuthorInfo ? (
                                <div className={css.authorInfo}>
                                    <FormattedMessage id="ListingCard.author" values={{ authorName }} />
                                </div>
                            ) : null}
                            <H5 className={css.country}>{countryOfOrigin}</H5>
                        </div>
                    </div>
                </div>
                <div>
                    <H6 className={css.title}>{truncateMessage(title, 10)}</H6>
                    <H5 className={css.description}>{truncateMessage(description, 50)}</H5>
                </div>
                <div className={css.cardBottom}>
                    <div className={css.buttonGroups}>
                        <Button className={css.proposerBtn}>
                            {proposalBtnText}
                        </Button>
                        {/* <Button className={css.saveBtn}>
                            {saveBtnText}
                        </Button> */}
                    </div>
                    <div className={css.postedDate}>{jobPostedAt}</div>
                </div>
            </div>
            <div className={css.info}>
                <div className={css.mainInfo}>
                    {locationMaybe}
                    {categoryMaybe}
                    {experienceMaybe}
                </div>
                <div className={css.timingInfo}>
                    {availabilityMaybe}
                    {pricingMaybe}
                </div>
            </div>
        </NamedLink>
    );
};

ListingJobCardComponent.defaultProps = {
    className: null,
    rootClassName: null,
    renderSizes: null,
    setActiveListing: null,
    showAuthorInfo: true,
};

ListingJobCardComponent.propTypes = {
    className: string,
    rootClassName: string,
    intl: intlShape.isRequired,
    listing: propTypes.listing.isRequired,
    showAuthorInfo: bool,

    // Responsive image sizes hint
    renderSizes: string,

    setActiveListing: func,
};

export default injectIntl(ListingJobCardComponent);

