import { transitions as bookingProcessTransitions } from '../transactions/transactionProcessBooking';
import { transitions as inquiryProcessTransitions } from '../transactions/transactionProcessInquiry';

export const bookingProcessRequestedTransitions = [
    bookingProcessTransitions.INQUIRE,
    bookingProcessTransitions.DOCTOR_COUNTER_OFFER,
    bookingProcessTransitions.DOCTOR_ACCEPT_OFFER,
    bookingProcessTransitions.ORGANIZATION_ACCEPT_OFFER,
    bookingProcessTransitions.ORGANIZATION_COUNTER_OFFER,
    bookingProcessTransitions.REQUEST_PAYMENT_AFTER_INQUIRY,
    bookingProcessTransitions.REQUEST_PAYMENT,
    bookingProcessTransitions.CONFIRM_PAYMENT,
    bookingProcessTransitions.BANK_PAYMENT,
    bookingProcessTransitions.OPREATOR_CONFIRM_BANK_TRANSFER,
    bookingProcessTransitions.BANKING_PAYMENT_AFTER_INQUIRY,
    bookingProcessTransitions.INVOICE_SENT_BY_OPERATOR,
    bookingProcessTransitions.SUBMIT_INVOICE_PAYMENT_BY_CUSTOMER,
];

export const bookingProcessActiveTransitions = [
    bookingProcessTransitions.ACCEPT,
    bookingProcessTransitions.OPERATOR_ACCEPT,
    bookingProcessTransitions.ACCEPT_FROM_BANK_TRANSFER,
    bookingProcessTransitions.OPERATOR_ACCEPT_FROM_BANK_TRANSFER,
];

export const bookingProcessDeliveredTransitions = [
    bookingProcessTransitions.COMPLETE,
    bookingProcessTransitions.OPERATOR_COMPLETE,
    bookingProcessTransitions.REVIEW_1_BY_PROVIDER,
    bookingProcessTransitions.REVIEW_2_BY_PROVIDER,
    bookingProcessTransitions.REVIEW_1_BY_CUSTOMER,
    bookingProcessTransitions.REVIEW_2_BY_CUSTOMER,
    bookingProcessTransitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
    bookingProcessTransitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
    bookingProcessTransitions.EXPIRE_REVIEW_PERIOD,
    bookingProcessTransitions.OPREATOR_COMPLETE_FROM_BANK_TRANSFER,
];


export const bookingProcessFailedTransitions = [
    bookingProcessTransitions.DOCTOR_DECLINE_OFFER,
    bookingProcessTransitions.EXPIRE_PAYMENT,
    bookingProcessTransitions.OPERATOR_DECLINE,
    bookingProcessTransitions.EXPIRE,
    bookingProcessTransitions.CANCEL
];


export const inquiryProcessRequestedTransitions = [
    inquiryProcessTransitions.APPLY,
    inquiryProcessTransitions.ACCEPT_DR,
    inquiryProcessTransitions.ACCEPT_ORG,
    inquiryProcessTransitions.COUNTER_OFFER_ORG,
    inquiryProcessTransitions.COUNTER_OFFER_DR,
    inquiryProcessTransitions.ORG_PROCEEDS
];

export const inquiryProcessActiveTransitions = [

];

export const inquiryProcessFailedTransitions = [
    inquiryProcessTransitions.DECLINE,
    inquiryProcessTransitions.OPERATOR_DECLINE,
    inquiryProcessTransitions.APPLICATION_EXPIRE,
]

export const allRequestedTransitions = [
    ...bookingProcessRequestedTransitions,
    ...inquiryProcessRequestedTransitions
];

export const allActiveTransitions = [
    ...bookingProcessActiveTransitions,
    ...inquiryProcessActiveTransitions
];

export const allDeliveredTransitions = [
    ...bookingProcessDeliveredTransitions
];

export const allFailedTransitions = [
    ...bookingProcessFailedTransitions,
    ...inquiryProcessFailedTransitions
]