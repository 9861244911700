import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import css from './OrderBreakdown.module.css';


export default function LineItemBookingTimingMaybe({ bookingTimes }) {
    if (!bookingTimes || !bookingTimes.startTime || !bookingTimes.endTime) {
        return null;
    }

    const { startTime, endTime } = bookingTimes;

    return (
        <div className={css.lineItem}>
            <div>
                <div className={css.itemLabel}><FormattedMessage id='OrderBreakdown.bookingStartTime' /></div>
                <span className={css.bookingTime}>{startTime}</span>
            </div>
            <div>
                <div className={css.itemLabel}><FormattedMessage id='OrderBreakdown.bookingEndTime' /></div>
                <span className={css.bookingTime}>{endTime}</span>
            </div>
        </div>
    )
}
