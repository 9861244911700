import moment from "moment";

export const getUserType = (user) => (user?.attributes?.profile?.publicData?.userRole);
export const isVerified = (user) => (user?.attributes?.emailVerified);
export const transformBookingData = (tx, booking) => {
    if (!tx || !booking) {
        return null
    };
    const { attributes: { protectedData: { bookingStart, bookingEnd , timeZone} } } = tx;
    const { attributes } = booking;
    return {
        ...booking,
        attributes: {
            seats: attributes.seats,
            start: moment(bookingStart).tz(timeZone).toDate(),
            end:  moment(bookingEnd).tz(timeZone).toDate(),
            state: attributes.state
        }
    }
}