/**
 * Transaction process graph for plain inquiries:
 *   - default-inquiry
 */

/**
 * Transitions
 *
 * These strings must sync with values defined in Marketplace API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const transitions = {
  APPLY: 'transition/apply',
  ACCEPT_ORG: 'transition/accept-org',
  DECLINE: 'transition/decline',
  OPERATOR_DECLINE: 'transition/operator-decline',
  APPLICATION_EXPIRE: 'transition/application-expire',
  COUNTER_OFFER_ORG: 'transition/counter-offer-org',
  COUNTER_OFFER_DR: 'transition/counter-offer-dr',
  ACCEPT_DR: 'transition/accept-dr',
  ORG_PROCEEDS: 'transition/org-proceeds',
  COMPLETE: 'transition/complete-inquiry'
};

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
export const states = {
  INITIAL: 'initial',
  APPLIED: 'applied',
  ORG_ACCEPTED: 'org-accepted',
  DECLINED: 'declined',
  APPLICATION_EXPIRED: 'application-expired',
  ORG_COUNTETRED: 'org-countered',
  DR_ACCEPTED: 'dr-accepted',
  COMPLETED: 'completed'
};

/**
 * Description of transaction process graph
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
export const graph = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'default-inquiry/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: states.INITIAL,

  // States
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.APPLY]: states.APPLIED,
      },
    },
    [states.APPLIED]: {
      on: {
        [transitions.ACCEPT_ORG]: states.ORG_ACCEPTED,
        [transitions.DECLINE]: states.DECLINED,
        [transitions.APPLICATION_EXPIRE]: states.APPLICATION_EXPIRED,
        [transitions.OPERATOR_DECLINE]: states.DECLINED,
        [transitions.COUNTER_OFFER_ORG]: states.ORG_COUNTETRED,
      }
    },
    [states.ORG_COUNTETRED]: {
      on: {
        [transitions.ACCEPT_DR]: states.DR_ACCEPTED,
        [transitions.COUNTER_OFFER_DR]: states.APPLIED
      }
    },
    [states.DR_ACCEPTED]: {
      on: {
        [transitions.ORG_PROCEEDS]: states.ORG_ACCEPTED
      }
    },
    [states.ORG_ACCEPTED]: {
      on: {
        [transitions.COMPLETE]: states.COMPLETED
      }
    }
  },
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [transitions.INQUIRE_WITHOUT_PAYMENT].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
// NOTE: this functions is added just for the sake of consistency
export const isCustomerReview = transition => {
  return false;
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
// NOTE: this functions is added just for the sake of consistency
export const isProviderReview = transition => {
  return false;
};

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
// NOTE: this functions is added just for the sake of consistency
export const isPrivileged = transition => {
  return false;
};

// Check when transaction is completed (booking over)
// NOTE: this functions is added just for the sake of consistency
export const isCompleted = transition => {
  return false;
};

// Check when transaction is refunded (booking did not happen)
// In these transitions action/stripe-refund-payment is called
// NOTE: this functions is added just for the sake of consistency
export const isRefunded = transition => {
  return false;
};

// NOTE: this functions is added just for the sake of consistency
// We don't know if inquiry is on-going or complete
export const statesNeedingProviderAttention = [];
